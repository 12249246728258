import React, { useEffect } from 'react';
import { FaApple } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';
import mockup from '../assets/iphone/mockup.png';
import ss1 from "../assets/images/ss1.jpeg";

function Hero() {
  useEffect(() => {
    AOS.init({
      duration: 1000,  // Animasyon süresi
      once: true,      // Animasyonların sadece bir kere çalışmasını sağlar
    });
  }, []);

  return (
    <div id='anasayfa' className='font-Yazi flex flex-col sm:flex-col md:flex-row justify-evenly items-center bg-[#1C1444] lg:h-[990px] h-full md:h-[990px] w-full text-white overflow-x-hidden'>

      {/* özet */}
      <div data-aos='fade-right' data-aos-duration='2000' className='text-center sm:text-left sm:ml-20  lg:ml-8 md:text-left lg:text-left lg:mx-8 '>

        <h1 className='font-Baslik mt-24 md:mt-0 text-3xl sm:text-4xl md:text-5xl lg:text-5xl xl:text-6xl font-bold leading-tight sm:leading-tight md:leading-tight lg:leading-tight xl:leading-tight md:ml-8 '>
          Falcın ile konuşmaya <br /> hazır ol
        </h1>

        <p className=' mt-5 text-base sm:text-lg md:text-xl lg:text-xl md:ml-8 '>
          Yeni myra uygulaması ile kendine özel falcınla sohbet etmeye hazırsın. <br /> Artık fal ayağınıza kadar geldi.
        </p>

        <div className='flex flex-col lg:flex-row mt-10  sm:items-start lg:items-center items-center md:flex-col md:ml-8 '>
          <button className=' w-[220px] mb-2 sm:mt-2 sm:mr-2 sm:w-[230px] sm:mb-0  px-4 py-3 rounded-full border-2 border-white hover:bg-white hover:text-purple-500 flex items-center transition-all duration-300'>
            <FaApple className='mr-2' size={20} />
            <span className='text-xs font-bold ml-1'>APP STORE'DAN İNDİR</span>
          </button>

          {/* Küçük ekranlar için boşluk */}
          <div className="sm:hidden h-1"></div>
          {/* 
          <button className='w-[220px] sm:mt-2 lg:ml-3 px-4 sm:w-[230px] py-3 rounded-full border-2 border-white hover:bg-white hover:text-purple-500 flex items-center  transition-all duration-300'>
            <FaGooglePlay className='mr-2' size={20} />
            <span className='text-xs font-bold'>PLAY STORE'DAN İNDİR</span>
          </button> */}
        </div>

      </div>

      {/* fotoğraf */}
      <div data-aos='fade-left' data-aos-duration='2000' className='p-20 sm:p-20 relative'>
        <img
          src={mockup}
          alt='My App Screenshot'
          className='max-w-md mx-auto h-auto '
        />

        <img
          src={ss1}
          alt='My App Screenshot 1'
          className=' absolute top-24 left-36 w-[310px] h-[675px] rounded-[40px] '
        />
      </div>
    </div>
  );
}

export default Hero;
