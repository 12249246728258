import React from 'react';
import { FaInstagram, FaTwitter, FaTiktok, FaFacebookF, FaLinkedinIn } from 'react-icons/fa';

const Footer = () => {
  return (
    <div id='iletisim' className='max-w-[1240px] mx-auto py-16 px-4 text-gray-300'>
      <div className='flex flex-col lg:flex-row lg:justify-between items-center'>
        <div className='mb-8 lg:mb-0 text-center lg:text-left'>
          <h1 className='text-[#1C1444] text-3xl font-bold '>MYRA APP.</h1>
          <h2 className='pt-2'>BY KAJUTECH</h2>
        </div>

        <div className='flex flex-col  gap-4 items-center '>
          <div className='flex gap-4'>
            <a href="https://www.instagram.com/kaju.tech/" target="_blank" rel="noopener noreferrer"><FaInstagram size="2em" /></a>
            <h1 ><FaTwitter size="2em" /></h1>
            <h1 ><FaTiktok size="2em" /></h1>
            <h1 ><FaFacebookF size="2em" /></h1>
            <h1 ><FaLinkedinIn size="2em" /></h1>
          </div>

          <div className='mt-4 pt-2  '>
            <a  href="https://api.kircibros.com/privacy-policy/">PRIVACY & POLICY</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
