/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';

import mockup from "../assets/iphone/mockup.png";
import { CiMobile3 } from 'react-icons/ci';
import { CgWebsite } from 'react-icons/cg';
import { MdDesignServices } from 'react-icons/md';
import ss1 from "../assets/images/ss1.jpeg";
import ss2 from "../assets/images/ss2.jpeg";
import ss3 from "../assets/images/ss3.jpeg";

const Spacer = ({ width }) => <div style={{ width }}></div>;

const AppPhoto = () => {
  const [iosIndex, setIosIndex] = useState(0);
  const intervalDuration = 1222222000;

  const Images = [ss1, ss2,ss3];

  useEffect(() => {
    const iosIntervalId = setInterval(() => {
      setIosIndex((prevIndex) => (prevIndex + 1) % Images.length);
    }, intervalDuration);

    return () => {
      clearInterval(iosIntervalId);

    };
  }, [iosIndex, Images.length, intervalDuration]);

  const FeatureCard = ({ title, description, icon, aosDirection }) => (
    <div className='mt-6 sm:mt-0 sm:mb-12 font-Yazi '>
      <div className='flex items-center flex-col sm:flex-row'>
        <div className={`flex ${aosDirection === 'right' ? 'justify-start' : 'justify-end'} w-full mx-auto items-center`}>
          <div className={`w-full sm:w-1/2 ${aosDirection === 'right' ? 'sm:pr-8' : 'sm:pl-8'} `} data-aos={`fade-${aosDirection}`} data-aos-duration="1200">
            <div className='bg-[#1C1444] m-2 sm:m-0 p-4 md:mx-[-18px] lg:mx-[-18px] rounded-lg shadow-lg group cursor-pointer ease-in duration-100 transition-all hover:scale-105 hover:duration-300'>
              <h3 className='text-2xl mb-3 text-white'>{title}</h3>
              <p className='text-15px text-white'>{description}</p>
            </div>
          </div>
        </div>

        <div className='rounded-full bg-[#584f82] border-white border-4 w-10 h-10 absolute left-1/2 transform -translate-x-1/2 -translate-y-2 sm:translate-y-0 flex items-center justify-center'>
          <figure>{icon}</figure>
        </div>
      </div>
    </div>
  );

  return (
    <div className='w-full overflow-x-hidden py-16 px-8 bg-white ' id="icerik">
      <h1 className='mb-20 items-center justify-center flex text-4xl font-Baslik'>KARŞINIZDA MYRA APP...</h1>
      <div className=' max-w-[1240px] mx-auto flex flex-col md:flex-col lg:flex-row items-center '>
        {/* IOS DIV */}
        
        <div data-aos='fade-left' data-aos-duration='2000' className='p-20 sm:p-20 relative'>
        <img
          src={mockup}
          alt='My App Screenshot'
          className='max-w-md mx-auto h-auto '
        />

        <img
          src={ss2}
          alt='My App Screenshot 1'
          className=' absolute top-24 left-36 w-[310px] h-[675px] rounded-[40px] '
        />
      </div>

        <div className='hidden md:block '>
          <Spacer width='200px' />
        </div>

        <div className='flex flex-col justify-center items-center sm:mb-12 sm:mt-24 lg:mt-0'>
          <div className=' text-2xl font-bold mb-12 font-Baslik'>
            <h1>Size Neler Sunuyoruz?</h1>
          </div>
          <div className='w-full py-3 px-2 sm:max-w-xl sm:mx-auto sm:px-0'>
            <div className='relative antialiased text-sm font-semibold'>
              <div className='hidden absolute w-1 sm:block bg-[#584f82] h-full left-1/2 transform -translate-x-1/2' data-aos="fade-down" data-aos-duration="1500"></div>
              <FeatureCard
                title="Fal"
                description="Haftalık, Aylık, Yıllık istediğin zaman diliminde fal bakma imkanı."
                icon={<CgWebsite className='text-white text-[18px]' />}
                aosDirection="right"
              />
              <FeatureCard
                title="Astroloji"
                description="Gezegenler ve burçlar astrolojinin vazgeçilmezlerinden. Bir de MYRA'nın gözünden bakın."
                icon={<CiMobile3 className='text-white text-[18px]' />}
                aosDirection="left"
              />
              <FeatureCard
                title="Myra"
                description="Myra size özel bir falcı edasıyla sohbet edebilen bir yapay zeka. Ona merhaba demek istemez misin?"
                icon={<MdDesignServices className='text-white text-[18px]' />}
                aosDirection="right"
              />
            </div>
          </div>
        </div>


      </div>

    </div>
  );
};

export default AppPhoto;
