import React from 'react';
import { useEffect } from "react";
import './input.css';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import AppPhoto from './components/AppPhoto';
import Download from './components/Download';
import Footer from './components/Footer';
import Aos from "aos";



function App() {

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      {/* bir tane navbar */}
      <Navbar />
      <main >
        {/* bir tane uygulamayı kısaca özetleyen hareketli bir giriş ekranı */}
        <Hero />

        {/* uygulamanın ekran görüntüleri hem android hem ios olacak şekilde ekranın sağında ve solunda olacak */}
        <AppPhoto />

        {/* İndirme linkleri ve qr kod */}
        <Download />

        {/* bir feedback kısmı  ve footer */}
        <Footer />
      </main>

    </>
  )
}

export default App