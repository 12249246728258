import React from 'react';
import { FaApple } from 'react-icons/fa';
import qr from '../assets/qr/qr.png';
import AOS from 'aos';
import 'aos/dist/aos.css';


function Download() {
  // AOS kütüphanesini başlat
  AOS.init();

  return (
    <div id='indir' className='font-Baslik flex flex-col md:flex-row justify-center items-center space-y-8 md:space-y-0 space-x-0 md:space-x-8 bg-[#1C1444] p-24 overflow-x-hidden'>

      {/* iOS download section */}
      <div data-aos='fade-right' data-aos-duration='2000' className='text-center'>
        <h2 className='text-3xl font-bold mb-4 text-white'>iOS</h2>
        {/* QR code for iOS */}
        <img
          src={qr} // Replace with your iOS QR code image source
          alt='iOS QR Code'
          className='mx-auto mb-8 h-[120px] bg-white rounded-3xl'
        />
        {/* App Store download link */}
        <a
          href='https://apps.apple.com/us/app/your-app-name/id123456789' // Replace with your google play link
          target='_blank'
          rel='noopener noreferrer'
          className='font-bold mr-0 mb-2 sm:mr-2 sm:mb-0 md:w-[230px] px-12 py-3 rounded-full border-2 text-white border-white hover:bg-white hover:text-purple-500 flex items-center transition-all duration-300'
        >
          <FaApple className='mr-2 ' size={20} />
          App Store
        </a>
      </div>

    </div>
  );
}

export default Download;
