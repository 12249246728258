import React, { useState, useEffect } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Navbar = () => {

  const [navBackground, setNavBackground] = useState(false);
  const [nav, setNav] = useState(false);

  const scrollItems = [
    { id: 'anasayfa', text: 'AnaSayfa' },
    { id: 'icerik', text: 'İçerik' },
    { id: 'indir', text: 'İndir' },
    { id: 'iletisim', text: 'İletişim', type: "button", mailto: 'mailto:miraproject777@gmail.com' },
  ];

  useEffect(() => {
    AOS.init({
      duration: 800,
      offset: 200,
      easing: 'ease-in-out',
      once: true,
    });

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const navbar = document.getElementById('navbar');

      if (scrollPosition > 0) {
        setNavBackground(true);
        navbar.classList.add('bg-white', 'shadow-md');
      } else {
        setNavBackground(false);
        navbar.classList.remove('bg-white', 'shadow-md');
      }
    };

    const handleResize = () => {
      if (window.innerWidth > 768) {
        setNav(false);
      }
    }

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleNav = () => {
    setNav(!nav);
  };

  const handleLinkClick = () => {
    setNav(false);
  };


  return (

    <div
      id="navbar"
      className={`font-Yazi fixed w-full top-0 z-10 transition-all ease-in-out duration-300 overflow-x-hidden ${navBackground ? 'bg-white shadow-md' : 'bg-transparent'
        }`}
    >
      <div className="flex justify-between items-center h-20">
        <h1
          data-aos="fade-down"
          data-aos-duration="2000"
          className={`w-full text-4xl ml-3 sm:ml-10 font-Baslik  font- ${navBackground ? 'text-[#1C1444]' : 'text-white'
            } `}
        >
          MYRA APP.
        </h1>

        <ul className={`text-${navBackground ? '[#1C1444]' : 'white'} hidden md:flex space-x-12 mr-10`} >
          {scrollItems && scrollItems.map((item) => (
            <li
              data-aos="fade-down"
              data-aos-duration="2000"
              className={item?.type === "button" ? 'btn-primary' : 'p-4 cursor-pointer'}
              key={item?.id}
            >
              {item?.type === "button" ? (
                <a href={item.mailto}>{item?.text}</a>
              ) : (
                <a onClick={handleLinkClick} href={`#${item?.id}`}>{item?.text}</a>
              )}
            </li>
          ))}
        </ul>

        <div onClick={handleNav} className={`text-${navBackground ? '[#1C1444]' : 'white'} md:hidden mr-6 block`} data-aos="fade-down"
          data-aos-duration="1000">
          {nav ? <AiOutlineClose size={30} /> : <AiOutlineMenu size={30} />}
        </div>


        <ul className={` fixed bottom-0 top-0 w-[60%] h-full  border-r border-r-gray-900  bg-[#1C1444] ease-in-out duration-500 ${nav ? 'left-0' : 'left-[-100%]'}`}>
          <h1 className='w-full text-3xl font-bold text-white m-4'>MYRA APP.</h1>
          {scrollItems && scrollItems.map((item) => (
            <li className='text-white p-4 hover:text-purple-700 cursor-pointer hover:bg-[#1C1434] duration-500 mt-12' key={item?.id}>
              {item?.type === "button" ? (
                <a href={item.mailto} onClick={handleLinkClick}>{item?.text}</a>
              ) : (
                <a onClick={handleLinkClick} href={`#${item?.id}`}>{item?.text}</a>
              )}
            </li>
          ))}
        </ul>

      </div>
    </div>
  );
};

export default Navbar;






